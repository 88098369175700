@charset "utf-8";
/* ============================================================ */
/* .about */
/* ============================================================ */
.about_outline .lnav__item--outline,
.about_facility .lnav__item--facility,
.about_history .lnav__item--history,
.about_parents .lnav__item--parents,
.about_ob .lnav__item--ob {
	background: $baseColor;
	color: $white;
}

.about_facility {
	.facility-list {
		img {
			border-radius: 5px;
		}
	}
}

.about_history {
	.ttl04 {
		margin-bottom: 0;
	}
	.history-block {
		padding: 25px 0 45px;
		&:last-child {
			padding: 25px 0 0;
		}
		&__date {
			color: $baseColor;
			position: relative;
			line-height: 1.0;
			font-size: 1.6rem;
			padding-left: 20px;
			margin-bottom: 5px;
			&::before {
				left: 0;
				@include centering-elements(false, true);
				width: 12px;
				height: 12px;
				content: "";
				border-radius: 50%;
				background: $baseColor;
				color: $baseColor;
				font-size: 2.2rem;
				line-height: 0.7;
			}
		}
		&__text {
			padding-left: 20px;
			font-size: $m;
			font-weight: $font-medium;
			padding-bottom: 20px;
		}
		.column > * {
			margin-bottom: 0;
		}
	}
}

.about_ob {
	.ob-item {
		margin-bottom: 40px;
		.accordion {
			background: #fff3f6;
			border: none;
			border-radius: 0;
			padding: 20px 30px 20px 20px;
			border: 1px solid #fff3f6;
			&__title {
				font-size: $l;
				font-weight: $font-medium;
				color: $baseColor;
			}
			&__icon {
				font-size: $xl;
				color: $textBrown;
				right: 10px;
			}
			&.active {
				.accordion__icon::before {
					content: "\f056";
				}
			}
		}
		.accordion-hide {
			border: 1px solid #ffb9c6;
			border-top: none;
			padding: 20px;
		}
	}
}