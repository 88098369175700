@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
.index {
	.visual {
		position: relative;
		overflow: hidden;
		
		&__body {
			position: absolute;
			top: 100px;
			left: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&__text {
				font-size: $xxl;
				color: $white;
				font-weight: $font-bold;
				line-height: 2.0;
				text-shadow: 0px 0px 8px #000;
				[data-ruby] {
					position: relative;
				}
				[data-ruby]::before {
					content: attr(data-ruby);
					position: absolute;
					top: -1em;
					left: 0;
					right: 0;
					margin: auto;
					font-size: 1.1rem;
					text-align: center;
					font-weight: $font-regular;
				}
			}
		}
	}
	
	.contents {
		overflow-x: hidden;
		.row {
			&__inner {
				padding: 40px 10px 50px;
			}
		}
	}
	
	.ttl02 {
		display: flex;
		align-content: center;
		flex-direction: column;
	}
	
	.news-list {
		margin: 0;
		&__item {
			padding: 0;
			margin-bottom: 30px;
			&__thum {
				width: 125px;
				height: 110px;
				img {
					height: 110px;
					max-width: none;
				}
			}
			&__text {
				background: $white;
				margin-left: -13px;
				position: relative;
				border-radius: 0px 13px 13px 13px;
				padding: 10px 15px;
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}
	.index-roots {
		.ttl02 {
			font-size: 2.1rem;
			&::after {
				display: none;
			}
			&__text02 {
				color: $baseColor;
				font-size: 3.6rem;
			}
		}
	}
	
	.index-contents {
		&__item {
			text-align: center;
			color: $white;
			padding: 35px 10px 30px;
			margin-bottom: 2px;
			&:first-child {
				background: url(/common/img/index/bg_index_01.png) no-repeat center center;
				background-size: cover;
			}
			&:nth-child(2) {
				background: url(/common/img/index/bg_index_02.png) no-repeat center center;
				background-size: cover;
			}
			&:last-child {
				background: url(/common/img/index/bg_index_03.png) no-repeat center center;
				background-size: cover;
			}
			&__ttl {
				font-size: 2.5rem;
				font-weight: $font-medium;
				margin-bottom: 15px;
			}
			.btn--md {
				border: none;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 200px;padding: 0 20px; }
			}
		}
	}
}