@charset "utf-8";
/* ============================================================ */
/* .life */
/* ============================================================ */
.life_education .lnav__item--education,
.life_day .lnav__item--day,
.life_lunch .lnav__item--lunch,
.life_event .lnav__item--event,
.life_activity .lnav__item--activity {
	background: $baseColor;
	color: $white;
	&:hover {
		background: $white;
		color: $textBrown;
	}
}

.life_day {
	.timeline {
		&__item {
			display: flex;
			position: relative;
			&::before {
				position: absolute;
				content: "";
				border-right: 4px solid #ffb8c5;
				height: 100%;
				width: 4px;
				top: 30px;
				left: 66px;
				z-index: -1;
			}
			&:last-child {
				&::before {
					display: none;
				}
				.timeline__item__text,
				.timeline__item__img {
					border-bottom: none;
				}
			}
			&__time {
				flex: 0 0 140px;
				margin-right: 30px;
				width: 140px;
				height: 140px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #fff3f6;
				color: $baseColor;
				flex-direction: column;
				font-size: $xl;
				line-height: 1.0;
				font-weight: $font-medium;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-right: 15px; }
			}
			&__text {
				width: 45%;
				padding-right: 10px;
				margin: 30px 0 22px;
				color: $baseColor;
				font-weight: $font-medium;
				font-size: $xl;
				border-bottom: 1px solid $borderColor;
				.btn {
					padding: 0 20px;
					min-width: inherit;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 10px; }
					font-size: $s;
					.icon-fix-right {
						right: 5px;
					}
				}
			}
			&__img {
				border-bottom: 1px solid $borderColor;
				display: flex;
				padding-bottom: 7px;
				margin-bottom: 22px;
			}
		}
	}
}

.life_lunch {
	.recipe-list {
		font-size: $s;
		&__item {
			padding-left: 13px;
			position: relative;
			&::before {
				@include centering-elements(false, true);
				width: 8px;
				left: 0;
				border-bottom: 1px solid $baseColor;
				content: "";
			}
		}
	}
}

.life_event {
	.event-list {
		&__item {
			border-bottom: 1px solid $borderColor;
			display: flex;
			padding: 40px 0;
			&:first-child {
				padding: 0 0 40px 0;
			}
			&:last-child {
				border-bottom: none;
			}
			&__text {
				width: 245px;
				&__month {
					font-size: 5.7rem;
					line-height: 1.0;
					color: $baseColor;
					font-weight: $font-medium;
					margin-bottom: 15px;
					&__number {
						font-size: 10.8rem;
						padding-right: 10px;
					}
				}
				&__name {
					&__item {
						position: relative;
						padding-left: 20px;
						font-size: $l;
						&::before {
							@include centering-elements(false, true);
							width: 12px;
							left: 0;
							border-bottom: 1px solid $baseColor;
							content: "";
						}
					}
				}
			}
			
			img {
				border-radius: 11px;
			}
		}
	}
}