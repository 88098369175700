@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
@media screen and (max-width: 375px) {
	html {
		font-size: calc(100vw / 37.5);
	}
}

body {
	background: $white;
}

.wrap {
	font-family: 'Noto Sans Japanese', sans-serif;
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
}

.pc-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	padding: 10px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	&__item {
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
		&__link {
			text-decoration: none;
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 0;
	bottom: 5px;
	
	.__img {
		max-width: 108.5px;
	}
}