@charset "utf-8";

@import url("//fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_about";
	@import "pc/_faq";
	@import "pc/_life";
	@import "pc/_guide";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_about";
	@import "sp/_faq";
	@import "sp/_life";
	@import "sp/_guide";
}
@import "_utility";



