@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-contact {
	border-top: 1px solid $borderColor;
	background: url(/common/img/common/bg_footer-contact.png) no-repeat;
	background-size: cover;
	&__inner {
		max-width: 1040px;
		padding: 70px 20px 100px;
		margin: 0 auto;
		text-align: center;
		
		.ttl02 {
			display: flex;
			align-content: center;
			flex-direction: column;
		}
	}
}

footer {
	text-align: center;
	line-height: 1.6;
	
	.footer__inner {
		max-width: 1040px;
		padding: 40px 20px 65px;
		margin: 0 auto;
	}

	.footer-logo {
		margin-bottom: 35px;
		display: flex;
		align-items: center;
		
		&__link {
			display: block;
			width: 247px;
			margin-right: 20px;
		}
		&__address {
			color: $textBrown;
			text-align: left;
			a[href^="tel:"] {
				color: $textBrown;
			}
		}
	}
	.footer__right {
		margin: 0 0 0 auto;
		display: flex;
		align-items: center;
		.__ttl {
			color: $baseColor;
            margin: 0 0 5px;
		}
		.__img {
			max-width: 114px;
			margin: 0 0 0 15px;
		}
	}
    .footer__sns {
		display: flex;
		justify-content: center;
        align-items: center;
        margin: 0 0 0 auto;
		&__item {
			margin: 0 5px;
			&__link {
				text-decoration: none;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $baseColor;
				color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $xl;
				transition: 0.3s all;
				.icon-line {
					font-size: $xxl;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	
	.fnav {
		display: flex;
		&__column {
			flex: 1 1 auto;
			margin: 0 20px 0 0;
			border-right: 1px solid $borderColor;
			text-align: left;
			width: calc(100% / 5 );
			@media screen and (min-width:  767.5px) and (max-width:1024px) {margin: 0 10px 0 0;}
			&:first-child {
				padding-left: 20px;
				border-left: 1px solid $borderColor; 
				@media screen and (min-width:  767.5px) and (max-width:1024px) {padding-left: 10px;}
			}
			&:last-child {
				margin: 0;
			}
			&__list {
				&__item {
					margin: 0 0 20px;
					font-size: $s;
					font-weight: $font-medium;
					color: $textBrown;
					&::before {
						content: "\f105";
						font-family: "icomoon";
						color: $baseColor;
						margin-right: 10px;
					}
					
					&:last-child {
						margin: 0;
					}
					
					&__text {
						&--target {
							pointer-events: none;
						}
					}
				
					&__link {
						color: $textBrown;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
			&__list__sub {
				margin-top: 10px;
				&__item {
					margin: 0 0 10px 18px;
					&:last-child {
						margin: 0 0 0 18px;
					}
					&__link {
						color: #9e928b;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.copyright {
		padding: 20px 0;
		color: #9e928b;
		font-size: $xs;
		border-top: 1px solid $borderColor;
	}
}