@charset "utf-8";
/* ============================================================ */
/* .about */
/* ============================================================ */
.about_outline .lnav__item--outline,
.about_facility .lnav__item--facility,
.about_history .lnav__item--history,
.about_parents .lnav__item--parents,
.about_ob .lnav__item--ob {
	background: $baseColor;
	color: $white;
	&:hover {
		background: $white;
		color: $textBrown;
	}
}

.about_facility {
	.facility-list {
		img {
			border-radius: 10px;
		}
	}
}

.about_history {
	.ttl04 {
		margin-bottom: 0;
	}
	.history-block {
		margin-left: 15px;
		border-left: 3px solid #ffb7c4;
		padding: 25px 0 45px;
		&:last-child {
			padding: 25px 0 0;
		}
		&__date {
			color: $baseColor;
			position: relative;
			line-height: 1.0;
			font-size: 1.6rem;
			padding-left: 20px;
			margin-bottom: 5px;
			&::before {
				left: -8px;
				position: absolute;
				width: 13px;
				height: 13px;
				content: "";
				border-radius: 50%;
				background: $baseColor;
				color: $baseColor;
				font-size: 2.2rem;
				line-height: 0.7;
			}
		}
		&__text {
			padding-left: 20px;
			font-size: 1.8rem;
			font-weight: $font-medium;
			padding-bottom: 30px;
		}
		.column > * {
			margin-bottom: 0;
		}
	}
}

.about_ob {
	.lnav--ob {
		.lnav__item {
			width: 20%;
			background: $baseColor;
			color: $white;
			
			&:hover {
				color: $textBrown;
				background: $white;
			}
		}
	}
	.ob-item {
		margin-bottom: 60px;
		.accordion {
			background: #fff3f6;
			border: none;
			border-radius: 0;
			padding: 20px 50px;
			border: 1px solid #fff3f6;
			&__title {
				font-size: $xl;
				font-weight: $font-medium;
				color: $baseColor;
			}
			&__icon {
				font-size: $xxxl;
				color: $textBrown;
				right: 30px;
			}
			&.active {
				.accordion__icon::before {
					content: "\f056";
				}
			}
		}
		.accordion-hide {
			border: 1px solid #ffb9c6;
			border-top: none;
			padding: 40px 50px;
		}
	}
}