@charset "utf-8";
/* ============================================================ */
/* .life */
/* ============================================================ */
.life_education .lnav__item--education,
.life_day .lnav__item--day,
.life_lunch .lnav__item--lunch,
.life_event .lnav__item--event,
.life_activity .lnav__item--activity {
	background: $baseColor;
	color: $white;
}

.life_day {
	.timeline {
		&__item {
			display: flex;
			position: relative;
			flex-wrap: wrap;

			&__time {
				border-radius: 5px 0 0 5px;
				display: flex;
				align-items: center;
				background: #fff3f6;
				color: $baseColor;
				font-size: $xl;
				line-height: 1.0;
				padding: 5px 10px;
				font-weight: $font-medium;
				width: 30%;
				margin-bottom: 15px;
			}
			&__text {
				background: #fff3f6;
				border-radius: 0 5px 5px 0;
				width: 70%;
				padding: 5px 10px;
				color: $baseColor;
				font-weight: $font-medium;
				font-size: $xl;
				line-height: 25px;
				margin-bottom: 15px;
				.btn {
					padding: 0 20px;
					font-size: $s;
					.icon-fix-right {
						right: 5px;
					}
				}
			}
			&__img {
				display: flex;
				margin-bottom: 20px;
			}
		}
	}
}

.life_lunch {
	.recipe-list {
		font-size: $s;
		&__item {
			padding-left: 13px;
			position: relative;
			&::before {
				@include centering-elements(false, true);
				width: 8px;
				left: 0;
				border-bottom: 1px solid $baseColor;
				content: "";
			}
		}
	}
}

.life_event {
	.event-list {
		&__item {
			border-bottom: 1px solid $borderColor;
			display: flex;
			flex-wrap: wrap;
			padding: 20px 0;
			&:first-child {
				padding: 0 0 20px 0;
			}
			&:last-child {
				border-bottom: none;
			}
			&__text {
				width: 100%;
				display: flex;
				margin-bottom: 15px;
				&__month {
					font-size: 5.0rem;
					line-height: 1.0;
					color: $baseColor;
					font-weight: $font-medium;
					
					margin-right: 20px;
					&__number {
						font-size: 9.0rem;
					}
				}
				&__name {
					&__item {
						position: relative;
						padding-left: 20px;
						font-size: $m;
						&::before {
							@include centering-elements(false, true);
							width: 12px;
							left: 0;
							border-bottom: 1px solid $baseColor;
							content: "";
						}
					}
				}
			}
			
			img {
				border-radius: 11px;
			}
		}
	}
}