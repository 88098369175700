@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	font-family: 'Noto Sans Japanese', sans-serif;
	color: $textColor;
	font-size: 1.5rem;
	line-height: 1.8;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	transition: 0.2s all;
	&:hover {
		text-decoration: none;
		
		img {
			opacity: 0.7;
		}
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	padding: 12px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1040px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		&__link {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 0;
	bottom: 5px;
	.__img {
		@include transition;
		max-width: 217px;
	}
	&__link:hover .__img {
		transform: rotate(-15deg);
	}
}