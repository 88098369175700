@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
   font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?e79yb5');
  src:  url('/common/icon/fonts/icomoon.eot?e79yb5#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?e79yb5') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?e79yb5') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?e79yb5') format('woff'),
    url('/common/icon/fonts/icomoon.svg?e79yb5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube-play:before {
  content: "\f16a";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-home:before {
  content: "\f015";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-tiktok:before {
  content: "\e901";
}
.icon-contact:before {
  content: "\e900";
}
