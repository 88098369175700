@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
.index {
	.visual {
		position: relative;
		overflow: hidden;
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&__text {
				font-size: 3.8rem;
				color: $white;
				font-weight: $font-bold;
				line-height: 1.0;
				text-shadow: 0px 0px 8px #000;
				[data-ruby] {
					position: relative;
				}
				[data-ruby]::before {
					content: attr(data-ruby);
					position: absolute;
					top: -1em;
					left: 0;
					right: 0;
					margin: auto;
					font-size: 1.9rem;
					font-weight: $font-regular;
					text-align: center;
				}
			}
		}
	}
	
	.contents {
		overflow-x: hidden;
		.row {
			&__inner {
				padding: 50px 20px 80px;
				&--narrow {
					max-width: 860px;
				}
			}
		}
	}
	
	.ttl02 {
		display: flex;
		align-content: center;
		flex-direction: column;
	}
	
	.news-list {
		&__item {
			padding: 0;
			flex-direction: column;
			width: calc((100% - 91px)/ 3);
			&__thum {
				width: 100%;
				padding-top: 67%;
				height: auto;
				
				& > * {
					width: 100%;
					min-height: 100%;
				}
			}
			&__text {
				background: $white;
				margin-top: -13px;
				position: relative;
				margin-left: 20px;
				border-radius: 13px 0 13px 13px;
				box-shadow:0px 0px 5px 1px #c0c0c0;
				padding: 25px;
				display: flex;
				flex-direction: column;
				align-self: stretch;
				height: 100%;
			}
			&:hover {
				opacity: 0.7;
				img {
					opacity: 1.0;
				}
			}
		}
	}
	
	.index-roots {
		.ttl02 {
			font-size: 2.1rem;
			&::after {
				display: none;
			}
			&__text02 {
				color: $baseColor;
				font-size: 4.6rem;
			}
		}
	}
	
	.index-contents {
		display: flex;
		justify-content: space-between;
		margin-left: -2px;
		margin-right: -2px;
		&__item {
			margin-left: 2px;
			margin-right: 2px;
			width: calc((100% - 2px) / 3);
			text-align: center;
			color: $white;
			padding: 80px 10px 80px;
			&:first-child {
				background: url(/common/img/index/bg_index_01.png) no-repeat center center;
				background-size: cover;
			}
			&:nth-child(2) {
				background: url(/common/img/index/bg_index_02.png) no-repeat center center;
				background-size: cover;
			}
			&:last-child {
				background: url(/common/img/index/bg_index_03.png) no-repeat center center;
				background-size: cover;
			}
			&__ttl {
				font-size: 2.5rem;
				font-weight: $font-medium;
				margin-bottom: 15px;
			}
			.btn--md {
				border: none;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 200px;padding: 0 20px; }
			}
		}
	}
	.index-bnr {
		& > * > * {
			transition: 0.3s all;
		}
	}
}