@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	.inner {
		display: flex;
		align-items: center;
		height: 60px;
		padding: 0 15px 0 10px;
	}

	.header-logo {
		flex: 1 1 auto;
		line-height: 0;
	}
	
	.header-contact {
		background: $baseColor;
		color: $white;
		height: 60px;
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		text-decoration: none;
		font-size: $l;
	}
	
	.header-menu,
	.header-menu span {
		@include transition;
	}

	.header-menu {
		position: relative;
		width: 28px;
		height: 22px;
		z-index: 10;
		cursor: pointer;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $baseColor;
			@include radius(2);

			&:nth-of-type(1) { top: 0; }
			&:nth-of-type(2) { top: 10px; }
			&:nth-of-type(3) { bottom: 0; }
		}
		&.active {
			.header-menu__item {
				background: $white;
				&:nth-of-type(1) { transform: translateY(10px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-10px) rotate(315deg); } 
			}
		}
	}

	.gnav {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 60px 0 15px;
		background: rgba($baseColor, 1.0);
		opacity: 0;
		@include transition;
		overflow-y: auto; 

		&__item {
			&__link {
				display: block;
				padding: 10px 15px;
				color: $white;
				text-decoration: none;
				position: relative;
				cursor: pointer;
				line-height: 1.6;
				border-bottom: 1px solid #e88c9d;
				&__icon {
					@include centering-elements(false, true);
					right: 10px;
					background: #c13053;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 25px;
					height: 25px;
					border-radius: 5px;
					.icon-angle-down {	
						color: $white;
						font-size: $s;
					}
				}
				&.active-gnav {
					.gnav__item__link__icon .icon-angle-down::before {
						display: block;
						transform: rotate(180deg);
					}
				}
			}
			&:first-child {
				.gnav__item__link {
					border-top: 1px solid #e88c9d;
				}
			}
		}
		.gnav-sub {
			display: none;
			&__item {
				&__link {
					border-bottom: 1px solid #e88c9d;
					color: $white;
					text-decoration: none;
					padding: 10px 15px;
					background: rgba($white, 0.15);
					display: block;
				}
			}
		}
		
		.show & {
			z-index: 2;
			opacity: 1;
		}
	}
}

