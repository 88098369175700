@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	
	.inner {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		max-width: 1040px;
		margin: 0 auto;
		padding: 0 20px;
		flex-wrap: wrap;
		position: relative;
	}
	
	.header-mark {
		position: absolute;
		top: 20px;
		left: 20px;
		width: 70px;
	}
	
	.header-contact {
		padding-top: 20px;
		margin-bottom: 40px;
		.btn--md {
			@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 180px;padding: 0 20px; }
		}
	}
	.header-logo {
		@include centering-elements(true, false);
		top: 20px;
		width: 300px;
		& > * > * {
			transition: 0.3s all;
		}
	}

	.gnav {
		display: flex;
		width: 100%;
		justify-content: space-between;
		z-index: 10;
		
		&__item {
			width: calc((100% - 0px) / 6 );
			position: relative;
			height: 80px;
			padding-bottom: 20px;

			&__link {
				height: 60px;
				border-left: 1px solid $borderColor;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-direction: column;
				font-weight: $font-medium;
				color: $textColor;
				text-decoration: none;
				line-height: 1.0;
				
			}
			&:nth-last-child(2) {
				.gnav__item__link {
					border-right: 1px solid $borderColor;
				}
			}
			.gnav-sub {
				top: 80px;
				left: 0;
				position: absolute;
				&__item {
					overflow: hidden;
					height: 0;
				}
			}
			&:hover {
				.gnav__item__link {
					color: $baseColor;
				}
				.gnav-sub {
					width: 100%;
					text-align: center;
					&__item {
						overflow: visible;
						height: 50px;
						background: $baseColor;
						opacity: 0.9;
						width: 100%;
						padding: 0 15px;
						transition: 0.3s all;
						&__link {
							color: $white;
							text-decoration: none;
							width: 100%;
							line-height: 45px;
							height: 100%;
							display: block;
							border-bottom: 1px solid $white;
						}
						&:last-child .gnav-sub__item__link {
							border-bottom: none;
						}
						&:hover {
							background: #dd3a61;
						}
					}
				}
			}
		}
	}
}
.news .gnav__item__link--news,
.about .gnav__item__link--about,
.life .gnav__item__link--life,
.guide .gnav__item__link--guide,
.preschooler .gnav__item__link--preschooler,
.faq .gnav__item__link--faq {
	color: $baseColor;
}

