@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-contact {
	border-top: 1px solid $borderColor;
	background: url(/common/img/common/bg_footer-contact.png) no-repeat;
	background-size: cover;
	&__inner {
		max-width: 100%;
		padding: 40px 10px 50px;
		margin: 0 auto;
		text-align: center;
		
		.ttl02 {
			display: flex;
			align-content: center;
			flex-direction: column;
		}
	}
}
footer {
	text-align: center;
	line-height: 1.6;

	.footer__inner {
		max-width: 100%;
		padding: 30px 0 0;
		margin: 0 auto;
	}
	
	.footer-logo {
		margin: 20px 0;
		
		&__link {
			display: block;
			width: 250px;
			margin: 0 auto 15px;
		}
		&__address {
			color: $textBrown;
			a[href^="tel:"] {
				color: $textBrown;
			}
		}
	}
	.footer__right {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px 0 40px;
		.__ttl {
			color: $baseColor;
            margin: 0 0 5px;
		}
		.__img {
			max-width: 114px;
			margin: 0 0 0 15px;
		}
	}
	.footer__sns {
		display: flex;
		justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        &__txt {
            width: 100%;
            margin: 0 0 15px;
            color: $baseColor;
        }
		&__item {
			margin: 0 5px;
			&__link {
				text-decoration: none;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $baseColor;
				color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $xl;
				transition: 0.3s all;
				.icon-line {
					font-size: $xxl;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
    }
	.fnav {
		border-top: 1px solid $borderColor;
		&__column {
			&__list {
				&__item {
					&__link,
					&__text {
						display: block;
						text-align: left;
						border-bottom: 1px solid $borderColor;
						padding: 10px 15px;
						color: $textBrown;
					}
					&__link {
						text-decoration: none;
						position: relative;
					}
					&__text {
						position: relative;
						cursor: pointer;
						line-height: 1.6;

						.icon-angle-down {
							right: 10px;
							color: $baseColor;
							font-size: $xxs;
							@include centering-elements(false, true);
						}
						&.active {
							.icon-angle-down::before {
								display: block;
								transform: rotate(180deg);
							}
						}
					}
				}
			}
			&__list__sub {
				display: none;
				&__item {
					text-align: left;
					border-bottom: 1px solid $borderColor;
					position: relative;
					&__link {
						padding: 10px 15px;
						display: block;
						color: #9e928b;
						text-decoration: none;
					}
				}
			}
			.icon-angle-right {
				right: 10px;
				color: $baseColor;
				font-size: $xxs;
				@include centering-elements(false, true);
			}
		}
	}

	.copyright {
		padding: 20px 0;
		color: $textBrown;
		font-size: $xxs;
	}
}