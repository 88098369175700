@charset "utf-8";
/* ============================================================ */
/* faq.html */
/* ============================================================ */
.faq {
	.faq-list {
		&__item {
			padding: 30px 0 50px;
			border-bottom: 1px solid $baseColor;
			&:first-child {
				padding: 0 0 50px;
			}
			&:last-child {
				border-bottom: none;
			}
			&__icon {
				width: 50px;
				height: 50px;
				border-radius: 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $xl;
				font-weight: $font-medium;
				color: $white;
				flex: 0 0 50px;
				margin-right: 20px;
			}
			&__title {
				display: flex;
				align-items: center;
				font-size: $xl;
				font-weight: $font-medium;
				color: $baseColor;
				line-height: 1.6;
				margin-bottom: 20px;
				.faq-list__item__icon {
					background: $baseColor;
				}
			}
			&__text{
				display: flex;
				.faq-list__item__icon {
					background: #95868a;
				}
			}
		}
	}
}