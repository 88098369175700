@charset "utf-8";
/* ============================================================ */
/* .guide */
/* ============================================================ */
.guide_apply .lnav__item--apply,
.guide_price .lnav__item--price,
.guide_childcare .lnav__item--childcare {
	background: $baseColor;
	color: $white;
	&:hover {
		background: $white;
		color: $textBrown;
	}
}

.guide_childcare {
	.child-flow {
		color: $baseColor;
		font-weight: $font-medium;
		font-size: 1.7rem;
		display: flex;
		align-items: center;
		background: #fff3f6;
		padding: 10px 15px;
		border-radius: 5px;
		margin-bottom: 10px;
		& > *:first-child {
			flex: 0 0 100px;
		}
	}
	.u-ml115 {
		margin-left: 115px;
	}
}